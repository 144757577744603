// core
import React from 'react';
import { graphql } from 'gatsby'
import { SliceZone } from '@prismicio/react'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import Masonry from 'react-masonry-css'
import useWindowSize from "../hooks/useWindowSize"

// components
import Layout from '../components/Layout'
import { Seo } from '../components/Seo'
import * as images from '../assets/images/microsites/tombogo'
import { components } from '../slices/instagram-section'
import { YoutubeVideo } from "../components/YouTubeVideo";

const Tombogo = ({data}) => {
  const pageContent = data.prismicMicrosite
  const { type, url } = pageContent
  const header_navigation = "Black"  
  const { meta_title, meta_description, social_card, body : slices } = data.prismicMicrosite.data
  const { width } = useWindowSize()

  const alternateLanguages = pageContent.alternate_languages || []
  const lang = "en-us"

  const activeDoc = { 
    lang,
    type,
    url,
    alternateLanguages,
  }

  const breakpointColumns = {
    default: 3,
    768: 2
  }

  const tombogoData = {
    heroTitle: "MAKING WAVES IN FASHION",
    featuredHeader: "BEHIND THE SCENES",
    featuredTitle1: "WHITE CLAW™ X ",
    featuredTitle2: "TOMBOGO",
    featuredBody: "We showed up in style to Highsnobiety's Neu York Pop-Up in September with White Claw™ Vodka and a new look as we debuted the first piece in our collab collection with designer TOMBOGO. Our limited edition Claw™ bags are made out of White Claw® Hard Seltzer can tabs from our freshest flavors and materials Tombogo found in his studio. They’re resourceful, they’re functional and most importantly, they look super fresh.",
    galleryHeader: "Neu York Pop-up 2023",
    galleryTitle1: "OUR COLLAB CLAW™",
    galleryTitle2: "BAGS MAKE WAVES AT",
    galleryTitle3: "THE NEU YORK EVENT",
    galleryReadMore1: "Read more about the event at ",
    galleryReadMore2: "HIGHSNOBIETY",
    galleryReadMore3: ".",
    galleryReadMoreLink: "https://www.highsnobiety.com/preview/?id=198332022&type=article&token=eyJhbGciOiJIUzI1NiJ9.eyJpZCI6IjE5ODMzMjAyMiIsImlhdCI6MTY5NDUyODYxMSwiaXNzIjoidXJuOmhzbmItY21zOmlzc3VlciIsImF1ZCI6InVybjpoc25iLWVkaXRvcjphdWRpZW5jZSIsImV4cCI6MTY5NzEyMDYxMX0.sPhuxTI4EcPNdNwLZF8wVsfJK-iZ9hY5IROT4wegJww",
  }

  return (
    <Layout currentPage="tombogo" activeDocMeta={activeDoc} header_navigation={header_navigation}>

      <Seo title={meta_title?.text} description={meta_description?.text} image={social_card?.url} activeDocMeta={activeDoc}  currentPage={"tombogo"}  />
      <section className="tom-hero">
        <h1 className='tom-hero__title visually-hidden'>{tombogoData.heroTitle}</h1>
        <div className='tom-hero__video'>
          <video className='tom-hero__video-src' autoPlay={true} playsInline={true} muted={true} loop={true}>
            <source src={images.tomHeroVideo} type="video/mp4" />
          </video>
        </div>
      </section>
      <section className='tom-featured'>
        <div className='tom-header-text'>{tombogoData.featuredHeader}</div>
        <div className='tom-featured__content'>
          <div className='tom-featured__video'>
            <div className='tom-featured__video-src'>
              <YoutubeVideo youtubeSrc={'https://www.youtube.com/embed/UXWgEHpJl48'}/>
            </div>
          </div>
          <div className='tom-featured__text'>
            <h2 className='tom-title-text'>{tombogoData.featuredTitle1}<br/>{tombogoData.featuredTitle2}</h2>
            <p className='tom-body-text'>{tombogoData.featuredBody}</p>
          </div>
        </div>
      </section>
      <section className='tom-gallery'>
        <div className='tom-header-text'>{tombogoData.galleryHeader}</div>
        {/* Mobile Gallery */}
        {width < 768 &&
          <Masonry
            breakpointCols={breakpointColumns}
            className="tom-gallery__img-container tom-gallery__img-container--mobile"
            columnClassName="tom-gallery__img-column"
          >
            {images.galleryMobile.map((image, index) => (
              <div className='tom-gallery__img-wrapper' key={index}>
                <img key={index} className="tom-gallery__img" src={image} alt="" /> 
              </div>
            ))}
          </Masonry>
        }
        {/* Desktop Gallery */}
        {width >= 768 &&
          <Masonry
            breakpointCols={breakpointColumns}
            className="tom-gallery__img-container"
            columnClassName="tom-gallery__img-column"
          >
            {images.galleryDesktop.map((image, index) => (
              <div className='tom-gallery__img-wrapper' key={index}>
                <img key={index} className="tom-gallery__img" src={image} alt="" /> 
              </div>
            ))}
          </Masonry>
        }
        <h2 className='tom-title-text'>{tombogoData.galleryTitle1}<br/>{tombogoData.galleryTitle2}<br/>{tombogoData.galleryTitle3}</h2>
        <div className='tom-body-text'>
          {tombogoData.galleryReadMore1} <a href={tombogoData.galleryReadMoreLink} target="_blank" rel="noreferrer">{tombogoData.galleryReadMore2}</a>{tombogoData.galleryReadMore3}
        </div>
      </section>
      <div className='tom-footer-logo'>
        <video className='tom-footer-logo__rotate-logo' autoPlay={true} playsInline={true} muted={true} loop={true}>
          <source src={images.tomRotateLogo} type="video/mp4" />
        </video>
      </div>
      <SliceZone slices={slices} components={components} />
    </Layout>
  );
}

export const query = graphql`
  query TombogoPageQuery {
  prismicMicrosite (uid: {eq: "whiteclawxtombogo"}) {
    _previewable
    url
    type
    id
    lang
    alternate_languages {
      id
      type
      lang
    }
    data {
      body {
        ... on PrismicMicrositeDataBodyInstagramSection {
          id
          slice_type
          primary {
            title {
              text
            }
            description {
              text
            }
            container_class {
              text
            }
            background_color
            copy_color
            widget_view
          }
        }
      }
      social_card{
        url
      }
      meta_description {
        text
      }
      meta_title {
        text
      }
    }
  }
}
`
export default withPrismicPreview(Tombogo)