import tomRotateLogo from './tombogo-rotate-logo.mp4'
import tomHeroVideo from './tombogo-hero-video.mp4'
import tomGallery1 from './tom-gallery01.webp'
import tomGallery2 from './tom-gallery02.webp'
import tomGallery3 from './tom-gallery03.webp'
import tomGallery4 from './tom-gallery04.webp'
import tomGallery5 from './tom-gallery05.webp'
import tomGallery6 from './tom-gallery06.webp'
import tomGallery7 from './tom-gallery07.webp'
import tomGallery8 from './tom-gallery08.webp'

const galleryDesktop = [
  tomGallery1,
  tomGallery2,
  tomGallery3,
  tomGallery4,
  tomGallery5,
  tomGallery6
]

const galleryMobile = [
  tomGallery3,
  tomGallery7,
  tomGallery2,
  tomGallery6,
  tomGallery8,
]

export { 
  tomRotateLogo,
  tomHeroVideo,
  galleryDesktop,
  galleryMobile
}